
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import UserRole from "@/components/UserRole.vue";

export default defineComponent({
  name: "kt-user-menu",
  components: {
    UserRole,
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const user = store.state.AuthModule.user;
    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/images/flags/united-states.svg",
        name: "English",
        code: "en",
      },
      cn: {
        flag: "/images/flags/china.svg",
        name: "Chinese",
        code: "cn",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      store.dispatch(Actions.SET_LANG, { lang: lang });
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      user,
    };
  },
});

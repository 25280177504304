
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/plugins";
export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    onMounted(async () => {
      //check if current user is authenticated

      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
